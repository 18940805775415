import React, { useState, useEffect } from "react";
import SpeakingImage from "../../assets/Speaking.png";
import WritingImage from "../../assets/Writing.png";
import ReadingImage from "../../assets/Reading.png";
import ListeningImage from "../../assets/Listening.png";

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const items = [
    {
      image: SpeakingImage,
      text: "Immersive  IELTS speaking preparation , featuring interactive sessions and real time feedback to enhance  your spoken English proficiency.",
      cardsText: "Speaking Unlimited",
    },
    {
      image: WritingImage,
      text: "Enhace your IELTS writing skills with structured practice tests and detailed feedback.",
      cardsText: "Writing Unlimited",
    },
    {
      image: ReadingImage,
      text: "Boost your IELTS reading skills with extensive practice test , comprehension strategies, and tips to efficiently tackle different types of reading passages and questions.",
      cardsText: "Reading Unlimited",
    },
    {
      image: ListeningImage,
      text: "Immersive experience of IELTS listening test, featuring a variety of audio , practice tests, and strategies to improve your ability to understand of spoken English.",
      cardsText: "Listening Unlimited",
    },
  ];

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, 3000); // Change the image every 3 seconds
      return () => clearInterval(interval);
    }
  }, [isHovered, items.length]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-4">
      <div
        className="w-full  max-w-6xl overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={items[currentIndex].image}
          alt={items[currentIndex].text}
          className="w-full h-auto object-cover transition-transform duration-500 ease-in-out border-gray-200 border-[2px] rounded-lg shadow-sm"
        />
        <p className="mt-4 text-center md:text-lg text-sm px-6">
          {items[currentIndex].text}
        </p>
      </div>
      <div className="flex mt-4 space-x-6 overflow-hidden">
        {items.map((item, index) => (
          <div
            key={index}
            className={`p-4 w-[3.5rem] md:w-36 lg:w-56 h-10 bg-white border  rounded-lg shadow cursor-pointer transition-transform duration-300 ease-in-out hover:scale-105  ${
              index === currentIndex ? "border-[#A033FF]" : "border-gray-300"
            }`}
            onClick={() => setCurrentIndex(index)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="h-full w-full flex justify-center items-center">
              <div
                className={`text-center text-[0.5rem] md:text-sm ${
                  index === currentIndex ? "text-[#A033FF]" : "text-black"
                } font-semibold`}
              >
                {item.cardsText}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
