import React, { useEffect, useState } from "react";
import CustomTextField from "../Components/CustomTextField";
import CustomTextArea from "../Components/CustomTextArea";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import "../Components/PhoneInput.css";
import axios from "axios";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const Loader = () => (
  <div className="loader border-4 border-t-4 border-gray-200 border-t-blue-600 rounded-full w-6 h-6 animate-spin mx-auto"></div>
);

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    elaboration: "",
    currentStudy: "",
    college: "",
    pursue: "",
  });
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  },[])

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });

  if (name === "email") {
    if (value === "") {
      setEmailError(""); 
    } else if (emailPattern.test(value)) {
      setEmailError(""); 
    } else {
      setEmailError("Please enter a valid email"); 
    }
  }
  };

  const handleChangeMobileNumber = (value) => {
    setFormData({ ...formData, mobile: value });

    if (value === "" || value==='91') {
      setMobileError(""); 
    } else if (isValidPhoneNumber("+" + value)) {
      setMobileError(""); 
    } else {
      setMobileError("Please Enter Valid Mobile Number"); 
    }
  };
  const handleregistration = async () => {
    if (
      formData.email !== "" &&
      formData.mobile !== "" &&
      formData.name !== "" &&
      formData.pursue !== "" &&
      formData.currentStudy !== "" &&
      formData.college !== "" && !isLoading &&
      emailPattern.test(formData.email) &&
      isValidPhoneNumber("+" + formData.mobile)
    ) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${serviceUrl}/user/send_register_email`,
          formData,
          {
            headers: genericHeaders(),
          }
        );
        if (response.status === 200) {
          enqueueSnackbar(response?.data,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              autoHideDuration: 2000, 
            }
          );
          setTimeout(() => {
            navigate("/");
            setIsLoading(false);
          }, 2000);
        } else {
          enqueueSnackbar("Oops, something went wrong", { variant: "error" ,anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 5000,});
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data, { variant: "error" ,anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 5000,});
      }
    } else {
      enqueueSnackbar("Please fill all fields", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 5000,
      });
    }
  };
  return (
    <div className="flex flex-col md:px-14 px-4">
      <div className="text-center mt-24 text-2xl lg:text-2xl xl:text-3xl ">
        <span className="text-black font-bold">
        Please fill out the form below for registration
        </span>
      </div>
      <div className="border-2 border-[#A033FF] bg-center bg-cover rounded-[50px] mt-[70px] py-10 px-10 md:px-28 mx-4 md:mx-32 ">
        <div className="flex justify-around gap-x-20 flex-wrap lg:flex-nowrap">
          <div className="w-full mt-14 flex flex-col gap-y-10">
            <CustomTextField
              label="Your Name"
              value={formData.name}
              handleChange={onChangeHandler}
              name="name"
              height="h-[60px]"
            />
            <CustomTextField
              label="Email Address"
              value={formData.email}
              handleChange={onChangeHandler}
              name="email"
              height="h-[60px]"
            />
            <h1 className="text-red-600 text-base font-normal">{emailError}</h1>
            <div className="mt-[-2.5rem]">
              <CustomTextField
                label="Current College"
                value={formData.college}
                handleChange={onChangeHandler}
                name="college"
                height="h-[60px]"
              />
            </div>
          </div>
          <div className="w-full mt-[50px] flex flex-col gap-y-10">
            <div className="text-sm mb-1 text-black">
              {" "}
              Mobile Number
              <span className="text-red-600 text-base font-normal">*</span>
            </div>
            <div className="w-full">
              <PhoneInput
                containerClass="w-full -mt-[40px]"
                inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
                country={"in"}
                value={formData.mobile}
                onChange={handleChangeMobileNumber}
              />
            </div>
            <h1 className="text-red-600 text-base font-normal">
              {mobileError}
            </h1>
            <div className="text-sm  text-black -mt-8">
              {" "}
              Current Study
              <span className="text-red-600 text-base font-normal">*</span>
            </div>
            <div className="w-full -mt-8">
              <select
                value={formData.currentStudy}
                onChange={onChangeHandler}
                name="currentStudy"
                className="mt-1 block w-full px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option className="cursor-pointer" value="B.Com">B.Com</option>
                <option value="BSC">BSC</option>
                <option value="BBA">BBA</option>
                <option value="B.Tech">B.Tech</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="text-sm  text-black mt-[0rem]">
              {" "}
              What you want to pursue
              <span className="text-red-600 text-base font-normal">*</span>
            </div>
            <div className="w-full -mt-8">
              <select
                value={formData.pursue}
                onChange={onChangeHandler}
                name="pursue"
                className="mt-1 block w-full px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="B.Com">B.Tech</option>
                <option value="BSC">M.Tech</option>
                <option value="BBA">MSC</option>
                <option value="B.Tech">MBA</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
        </div>
        <div className="w-full flex mt-6">
          <div className="flex justify-center w-full">
            <CustomTextArea
              label="Do you want to elaborate anything"
              rows={4}
              value={formData.elaboration}
              handleChange={onChangeHandler}
              name="elaboration"
            />
          </div>
        </div>
        <div className="text-center mt-14 md:ml-[-3.5rem]">
          <button
            disabled={isLoading}
            className={`rounded-[40px] font-extrabold lg:text-[28px] text-black ${
              formData.email === "" ||
              formData.mobile === "" ||
              formData.name === "" ||
              !emailPattern.test(formData.email) ||
              !isValidPhoneNumber("+" + formData.mobile) ||
              formData.college === "" ||
              formData.currentStudy === "" ||
              formData.pursue === ""
                ? "border border-[#A033FF]  text-black cursor-not-allowed"
                : "bg-blue-600 text-white cursor-pointer"
            } py-2 px-4 w-full sm:w-[284px] h-10 lg:h-[64px] text-base leading-normal font-avenir mb-14 `}
            onClick={()=>{
              console.log(isLoading,"inside onclick")
              if(!isLoading){

                handleregistration()
              } 
            }}
          >
            {isLoading ? <Loader /> : "Register For Free"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
