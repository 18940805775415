import React, { useState } from "react";
import ImageCarousel from "./Demo";

// import IELTSHome from "../../assets/SpeakingIELTSHome.png";
// import IELTSProgress from "../../assets/ProgressIELTS.png";
// import IELTSFeedback from "../../assets/ResultIELTS.png";

// const TestImage = ({ isSelected, name }) => {
//   if (isSelected && name === "Home") {
//     return <img src={IELTSHome} alt="IELTS" className="scale-75  " />;
//   } else if (isSelected && name === "Progress") {
//     return <img src={IELTSProgress} alt="IELTS" className="scale-75 " />;
//   } else if (isSelected && name === "AIFeedback") {
//     return <img src={IELTSFeedback} alt="IELTS" className="scale-75 " />;
//   } else {
//     return null;
//   }
// };

const IELTS = () => {
  const [test, setTest] = useState([
    {
      name: "Home",
      content: "All the inclusive feature related to real time exam IELTS",
      isSelected: true,
      id: 1,
    },
    {
      name: "Progress",
      content: "More Accurate than Human Minds.",
      isSelected: false,
      id: 2,
    },
    {
      name: "AIFeedback",
      content: "Analysis you will fall in love with.",
      isSelected: false,
      id: 3,
    },
  ]);

  const handleClick = (id) => {
    const updatedTest = test.map((item) => ({
      ...item,
      isSelected: item.id === id,
    }));
    setTest(updatedTest);
  };

  return (
    <div className="flex flex-col text-center lg:text-center mb-20 md:px-14 px-4  py-20 bg-gray-50">
      <div className="flex flex-col">
        <div className="text-[#4E5664] text-2xl md:text-[32px]">
          {" "}
          TAKE A CLOSER LOOK
        </div>
        <div className="pt-4 text-4xl md:text-5xl font-extrabold">
          All about IELTS!{" "}
        </div>
      </div>
      <div className="flex justify-center items-center mt-4 text-black font-medium md:text-2xl text-sm">
        <p>
          Get full-length Computer-based IELTS Academic and General Practice
          Tests (Reading, Listening, Writing, and Speaking).
        </p>
      </div>
      {/* {test.map((item) => (
        <TestImage
          key={item.id}
          isSelected={item.isSelected}
          name={item.name}
        />
      ))} */}
      <ImageCarousel />
      {/* <div className="flex justify-between">
        {test.map((item) => (
          <div
            key={item.id}
            onClick={() => handleClick(item.id)}
            className={`md:w-1/5 w-[30%] cursor-pointer md:text-2xl text-sm font-medium border-t-2 ${
              item.isSelected ? "border-purple-600" : "border-[#E3E5E9] text-gray-500"
            } md:pt-7 pt-2`}
          >
            {item.content}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default IELTS;
