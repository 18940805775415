import React from "react";
import audio from "../../assets/audio.png";


const ChooseCambri = () => {
  return (
    <div className="flex flex-col md:px-14 px-4 ">
      <h1 className="text-[#0C0C0C] text-center font-medium md:text-5xl text-[2rem] items-center ">
      Reach out to Cambri.
      </h1>
      <div className="flex gap-x-[5rem] gap-y-[1rem] md:gap-[2rem] justify-center md:mt-[2rem] mt-[1rem] flex-wrap">
        <div className="flex flex-col">
          <img className="md:max-w-[50%] max-w-[30%]" src={audio} alt="Audio" />
          <div className="mt-2">
            <h1 className="text-[#0C0C0C] md:text-[1rem] text-[0.75rem] font-medium">
              24x7 Support
            </h1>
            <div className="flex  flex-col gap-0 justify-center text-[#737E93] md:text-[0.75rem] text-[0.5rem] font-medium mt-2">
              <p> With 24x7 Support</p> <p>we care for your</p> <p>needs.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <img className="md:max-w-[50%] max-w-[30%]" src={audio} alt="Audio" />
          <div className="mt-2">
            <h1 className="text-[#0C0C0C] md:text-[1rem] text-[0.75rem] font-medium">Reliable</h1>
            <div className="flex  flex-col justify-center text-[#737E93] md:text-[0.75rem] text-[0.5rem] font-medium mt-2">
              <p> With 24x7 Support</p> <p>we care for your</p> <p>needs.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <img className="md:max-w-[50%] max-w-[30%]" src={audio} alt="Audio" />
          <div className="mt-2">
            <h1 className="text-[#0C0C0C] md:text-[1rem] text-[0.75rem] font-medium">Affordable</h1>
            <div className="flex  flex-col justify-center text-[#737E93] md:text-[0.75rem] text-[0.5rem] font-medium mt-2">
              <p> With 24x7 Support</p> <p>we care for your</p> <p>needs.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <img className="md:max-w-[50%] max-w-[30%]" src={audio} alt="Audio" />
          <div className="mt-2">
            <h1 className="text-[#0C0C0C] md:text-[1rem] text-[0.75rem] font-medium">Quality</h1>
            <div className="flex  flex-col justify-center text-[#737E93] md:text-[0.75rem] text-[0.5rem] font-medium mt-2">
              <p> With 24x7 Support</p> <p>we care for your</p> <p>needs.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ChooseCambri;
