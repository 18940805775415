import React, { useRef, useState, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import Hero from "../Components/Home/Hero";
import PerfectMatch from "../Components/Home/PerfectMatch";
import PracticeImprove from "../Components/Home/PracticeImprove";
import ChooseCambri from "../Components/Home/ChooseCambri";
import IELTS from "../Components/Home/IELTS";
import SOP from "../Components/Home/SOP";
import SAT from "../Components/Home/SAT";
import ExamInterface from "../Components/Home/ExamInterface";
import BelieveUs from "../Components/Home/BelieveUs";
import ImageCarousel from "../Components/Home/Demo";

const Home = () => {

  return (
    <div className="flex flex-col">
      <Hero />,
    <PerfectMatch />,
    <IELTS />,
    <SOP />,
    <SAT />,
    <ExamInterface />,
    <PracticeImprove />,
    {/* <ImageCarousel/> */}
    {/* <BelieveUs />, */}
    <ChooseCambri />
    </div>
  );
};

export default Home;
