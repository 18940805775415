import React, { useState } from 'react';

const CustomTextArea = (props) => {
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [error, setError] = useState('');
  const wordLimit = 230; 

  const handleChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/).filter(word => word.length > 0);
    if (words.length <= wordLimit) {
      setText(inputText);
      setWordCount(words.length);
      setError('');
      if (props.handleChange) {
        props.handleChange(event);
      }
    } else {
      setError(`Word limit of ${wordLimit} exceeded. Please shorten your text.`);
    }
  };

  return (
    <div className={`${props.label !== "Do you want to elaborate anything" ? "ml-0" : "ml-0 md:ml-[-12rem]"}`}>
      <label className={`block mb-2 ${props.label !== "Do you want to elaborate anything" ? "ml-0" : "ml-0 md:ml-[12rem] text-xl font-semibold"}`}>
        {props.label}
      </label>
      <textarea
        {...props}
        value={text}
        className={`border border-[#DBDBDB] rounded-md ${props.label !== "Do you want to elaborate anything" ? "w-full" : "w-full md:w-[100%] md:ml-[95px]"} px-4 py-5 bg-white text-[#4E5664]`}
        onChange={handleChange}
      />
      <div className="text-right mt-1 text-sm text-gray-500">
        {wordCount}/{wordLimit} words
      </div>
      {error && (
        <div className="text-red-500 mt-1 text-sm">
          {error}
        </div>
      )}
    </div>
  );
};

export default CustomTextArea;
