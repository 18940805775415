import React from "react";
import SatImage from "../../assets/sat.png";
import ComputerImage from "../../assets/computer1.svg";
import TestingImage from "../../assets/testing1.svg";
import StudentImage from "../../assets/students.svg"

const SAT = () => {
  return (
    <div className="md:flex  flex-col text-center lg:text-left md:px-14 px-4 py-20  bg-gray-50 md:mb-32 mb-20">
      <p className="text-[#4E5664] text-2xl md:text-[32px]">
        THERE’S MORE TO OFFER{" "}
      </p>
      <p className=" mt-2 text-4xl md:text-5xl text-[#4E5664] font-medium">
        Check out <span className="text-neutral-950 font-extrabold">SAT!</span>
      </p>
      <div className="md:flex md:flex-row-reverse md:justify-between md:items-center mt-4">
        <img src={SatImage} alt="SAT" className="md:w-[70%] w-full" />
        <div className="my-auto ">
          <div className="text-[#5057FF] md:text-4xl text-2xl font-bold">
            Genuine Test Experience:
          </div>
          <ul className="mt-4  lg:text-2xl font-medium  text-lg space-y-2 text-[#202020] text-left ">
            <div className="flex space-x-2">
            <img src={ComputerImage} alt="ComputerImage"/>
            <li>Real test environment</li>
            </div>
            <div className="flex space-x-2">
            <img src={StudentImage} alt="StudentImage"/>
            <li>Tested and trusted by students</li>
            </div>
            <div className="flex space-x-2">
            <img src={TestingImage} alt="TestingImage"/>
            <li>Versatile testing with real-time feedback</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SAT;
