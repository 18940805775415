import React from "react";
import One from "../../assets/1.png";
import Two from "../../assets/2.png";
import Three from "../../assets/3.png";

const PracticeImprove = () => {
  return (
    <div className="flex flex-col md:px-14 px-4 ">
      <div className="md:text-5xl text-[#0C0C0C] text-2xl md:font-medium font-bold text-center lg:text-left">
      Practice. Improve. Succeed.
      </div>
      <div className="flex flex-col md:flex-row justify-around my-14 ">
        <div className="flex flex-col w-full md:w-1/2 h-full text-[#0C0C0C] md:py-0">
          <div className="flex w-full h-full">
            <div className="md:w-full h-full">
              <img src={One} alt="1" />
            </div>
            <div className="md:w-auto w-1/2 md:text-lg text-[0.75rem] flex md:justify-center items-center md:leading-5 leading-4 lg:-ml-[2.25rem] md:ml-[0.5rem]">
              Practice from the extensive library of 1000+ tests & analyse your
              performance.{" "}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 h-full text-[#0C0C0C] md:py-0">
          <div className="flex w-full h-full">
            <div className="md:w-full  h-full ml-10">
              <img src={Two} alt="2" />
            </div>
            <div className="md:text-lg md:w-auto w-1/2 text-[0.75rem] flex md:justify-center items-center md:leading-5 leading-4 ">
              Take 1-on-1 guidance from your tutor. For more content, please
              write it.
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 h-full text-[#0C0C0C] md:py-0">
          <div className="flex w-full h-full">
            <div className="md:w-[150%]  h-full ml-24">
              <img src={Three} alt="3" />
            </div>
            <div className="md:text-lg md:w-auto w-1/2 text-[0.75rem] flex md:justify-center items-center md:leading-5 leading-4 md:ml-[0rem]">
              Work on your weak areas. Improve your score. And boom, success!!{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center font-extrabold lg:text-[12.5rem] md:text-[8.5rem] text-[4rem] text-[#0C0C0C] lg:mb-[0rem] md:mb-[0rem] mb-[3rem]">
        GO!!
      </div>
    </div>
  );
};

export default PracticeImprove;
