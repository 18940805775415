import React, { useState, Fragment, useEffect } from "react";
import Logo from "../../src/assets/Logo.png";
import mobile from "../../src/assets/mobile.png";
import email from "../../src/assets/email.png";
import chooseCambri from "../../src/assets/chooseCambri.png";
import gradient from "../../src/assets/gradient.png";
import Topbar from "./Topbar/Topbar";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItems,
  Transition,
  MenuItem,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const Layout = ({ children }) => {
  let tests = [
    { name: "IELTS", url: "https://ielts-web-app-production.web.app/login" },
    { name: "SAT", url: "https://business.sat.cambri.ai/" },
  ];
  const navigateUrl = (url) => {
    window.open(url, "_blank");
  };
  const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  const handleBookDemoClick = () => {
    setToggle((prev) => !prev);
    navigate("/bookademo");
  };
  const handleRegistrationClick = () => {
    setToggle((prev) => !prev);
    navigate("/registration");
  };

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  },[])
  return (
    <div className="overflow-hidden">
      <div className="fixed top-0 z-50 w-full">
        {/* <Topbar /> */}
        <header className="border boxShadowHeader w-full top-0 bg-white z-10 sticky h-[5rem] flex shrink-0 items-center md:px-14 px-4 justify-between">
          <div className="flex gap-6">
            <img src={Logo} alt="logo" className="w-[40%] sm:w-auto" onClick={()=>{
              navigate('/')
            }} />
            <ul
              className={`hidden lg:flex flex-row mt-4 text-sm lg:text-sm xl:text-xl font-[850] lg:flex-row lg:space-x-8 lg:mt-[6.2px] uppercase`}
            >
              {
                <li>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton
                        className={`inline-flex w-full justify-center text-20px rounded-md bg-black bg-opacity-0 px-4  hover:bg-opacity-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 block py-2 pl-2 pr-2 lg:mt-[2px] 'text-black' `}
                      >
                        PRODUCTS
                        <ChevronDownIcon
                          className={`ml-2 -mr-1 h-5 w-5 lg:-mt-[2.3px] xl:mt-[0.5px] text-black`}
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute left-0 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                        <div className="px-1 py-1 ">
                          <MenuItem>
                            {
                              <button
                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-[#8431FC] hover:text-white`}
                                onClick={() =>
                                  navigateUrl("https://web.cambri.ai/ielts")
                                }
                              >
                                IELTS
                              </button>
                            }
                          </MenuItem>
                        </div>
                        <div className="px-1 py-1 ">
                          <MenuItem>
                            {
                              <button
                                className={` group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-[#8431FC] hover:text-white`}
                                onClick={() =>
                                  navigateUrl("https://web.cambri.ai/sop")
                                }
                              >
                                SOP Writer
                              </button>
                            }
                          </MenuItem>
                        </div>
                        <div className="px-1 py-1 ">
                          <MenuItem>
                            {
                              <button
                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-[#8431FC] hover:text-white`}
                                onClick={() =>
                                  navigateUrl("https://web.cambri.ai/sat")
                                }
                              >
                                SAT
                              </button>
                            }
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Transition>
                  </Menu>
                </li>
              }
              {/* {
              <li>
                  <button
                    onClick={() => navigateUrl("https://web.cambri.ai/ielts")}
                    className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                  >
                    IELTS
                  </button>
                </li>
            }
            {
              <li>
                <button
                  onClick={() => navigateUrl("https://web.cambri.ai/sop")}
                  className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                >
                  SOP Writer
                </button>
              </li>
            } */}
              <li>
                <button
                  onClick={() => navigateUrl("https://web.cambri.ai/aboutus")}
                  className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                >
                  About Us
                </button>
              </li>
              {
                <li>
                  <button
                    onClick={() => navigateUrl("https://web.cambri.ai/blogs")}
                    className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                  >
                    Blogs
                  </button>
                </li>
              }
            </ul>
          </div>
          <div className="hidden lg:flex">
            {/* <button className="bg-[#F1F2F4]  rounded-2xl sm:rounded-3xl sm:px-4 sm:py-3 sm:text-sm font-bold text-[10px] px-2 mr-2"  onClick={() =>navigateUrl('https://app.cambri.ai/login')}>
            LOGIN
          </button>
          <button className="bg-[#A033FF] rounded-3xl px-4 py-3 text-[0.7rem] font-bold text-white" onClick={() => navigateUrl('https://app.cambri.ai/signupnew')}>
            GET STARTED
          </button> */}
            <div className="flex gap-4">
              {/* {window.location.pathname !== "/registration" && (
                <button
                  className="bg-white rounded-xl px-6 py-3 text-xl font-bold text-black  border-2 border-[#8431FC]"
                  onClick={() => navigate("/registration")}
                >
                  Register For Free
                </button>
              )} */}
              <button
                className="bg-white rounded-xl px-6 py-3 text-xl font-bold text-black  border-2 border-[#8431FC]"
                onClick={() => navigate("/bookademo")}
              >
                Book a demo
              </button>
            </div>
          </div>
          <div className="lg:hidden">
            <p
              onClick={() => {
                setToggle((prev) => !prev);
              }}
            >
              {toggle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              )}
            </p>
            <ul
              className={`absolute ${
                toggle ? "hidden" : "flex"
              } flex-col items-center py-8 mt-10 space-y-6 font-bold bg-white top-14 left-6 right-6 drop-shadow-md sm:w-auto sm:self-center`}
            >
              {
                <li>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton
                        className={`inline-flex w-full justify-center text-20px rounded-md bg-black bg-opacity-0 px-4  hover:bg-opacity-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 block py-2 pl-2 pr-2 lg:mt-[2px] 'text-black' `}
                      >
                        PRODUCTS
                        <ChevronDownIcon
                          className={`ml-2 -mr-1 h-5 w-5 -mt-[6px] text-black`}
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-0 mt-2 w-40 sm:w-[200px] origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          <MenuItem>
                            {
                              <button
                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                onClick={() =>
                                  navigateUrl("https://web.cambri.ai/ielts")
                                }
                              >
                                IELTS
                              </button>
                            }
                          </MenuItem>
                        </div>
                        <div className="px-1 py-1 ">
                          <MenuItem>
                            {
                              <button
                                className={` group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                onClick={() =>
                                  navigateUrl("https://web.cambri.ai/sop")
                                }
                              >
                                SOP Writer
                              </button>
                            }
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Transition>
                  </Menu>
                </li>
              }
              {/* {
              <li>
                <button
                  onClick={() => navigateUrl("https://web.cambri.ai/ielts")}
                  className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                >
                  IELTS 
                </button>
              </li>
            }
            {
              <li>
                <button
                  onClick={() => navigateUrl("https://web.cambri.ai/sop")}
                  className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                >
                  SOP Writer
                </button>
              </li>
            } */}
              <li>
                <button
                  onClick={() => navigateUrl("https://web.cambri.ai/aboutus")}
                  className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                >
                  About Us
                </button>
              </li>
              {
                <li>
                  <button
                    onClick={() => navigateUrl("https://web.cambri.ai/blogs")}
                    className={`block py-2 pl-2 pr-2 text-black hover:text-[#8431FC]`}
                  >
                    Blogs
                  </button>
                </li>
              }
              {/* <li>
                {window.location.pathname !== "/registration" && (
                  <button
                    className="bg-white rounded-xl px-6 py-3 text-xl font-bold text-black  border-2 border-[#8431FC]"
                    onClick={handleRegistrationClick}
                  >
                    Register For Free
                  </button>
                )}
              </li> */}
              <li>
                <button
                  className="bg-white rounded-xl px-6 py-3 text-xl font-bold text-black  border-2 border-[#8431FC]"
                  onClick={handleBookDemoClick}
                >
                  Book a demo
                </button>
              </li>
            </ul>
          </div>
        </header>
      </div>
      <div className="mt-20">{children}</div>
      <div className="mt-20 -mb-[1rem] relative overflow-hidden">
        <div className="flex">
          <img
            className="w-full relative "
            src={chooseCambri}
            alt="chooseCambri"
          />
          <img
            className="w-full absolute h-[100%]"
            src={gradient}
            alt="addImg"
          />
          <div className="flex flex-col absolute md:top-[4rem]  top-[0rem] md:w-[50%] w-[45%] h-[100%] left-[55%] gap-[1rem] lg:gap-8 lg:-mt-[20%] md:-mt-[60%] md:gap-6 ">
            <h1 className="text-[0.75rem] text-[#FFF] font-medium md:text-[2.1rem] lg:text-[1.75rem] md:mt-[36rem] mt-10">
              Share with others, how you did it.
            </h1>

            <h1 className="text-[1.25rem] text-[#FFF] font-extrabold md:text-[1.8rem] ">
              Choose cambri for success
            </h1>
            <div>
              <p className="text-[0.51rem] text-[#FFF] font-normal md:text-[1.1rem]">
                Your one stop platform to prep for all types
              </p>
              <p className="text-[0.60rem] text-[#FFF] font-normal md:text-[1.1rem]">
                of international higher education exams
              </p>
            </div>
            <ul className="text-[0.75rem] text-[#FFF] font-medium  list-disc ml-10 md:text-[1.25rem]">
              <li>IELTS</li>
              <li>SAT</li>
              <li>SOP</li>
              {/* <p className="text-[1rem] text-[#FFF] font-medium md:text-[1.25rem]">
                and more...
              </p> */}
            </ul>
          </div>
        </div>
      </div>
      <footer className=" w-full  md:h-[27rem] h-1/6 bg-[#0C0C0C] md:px-28 px-2 pb-6">
        <div className="h-[90%] text-[#8F98A9] flex md:pt-20 pt-[2rem] md:w-auto w-full flex-wrap">
          <div className="md:w-1/3  w-full md:flex-row flex flex-col  md:justify-between  md:ml-0 ml-4">
            <div className="flex flex-col  gap-4 md:text-base">
              <p className="text-[#ABB2BE] text-base font-extrabold">
                ABOUT US
              </p>
              <p
                className="text-[#FFFFFF] text-sm cursor-pointer"
                onClick={() => navigateUrl("https://web.cambri.ai/aboutus")}
              >
                {" "}
                Who we Are
              </p>
              <p
                className="text-[#FFFFFF] text-sm cursor-pointer"
                onClick={() => navigateUrl("https://web.cambri.ai/contactus")}
              >
                Contact Us
              </p>
            </div>
            <div className=" flex flex-col gap-4 md:text-base md:my-0 my-10">
              <p className="text-[#ABB2BE] text-base font-extrabold">
                FOR STUDENTS
              </p>
              {tests.map((item, index) => (
                <p
                  className="text-[#FFFFFF] text-sm cursor-pointer"
                  onClick={() => navigateUrl(item.url)}
                >
                  {item.name}
                </p>
              ))}
            </div>
          </div>
          <div className="md:w-2/3  w-full  flex md:justify-end gap-4">
            <div className="flex flex-col gap-1 md:text-base md:ml-0 ml-4">
              <p className="text-[#ABB2BE] text-base font-extrabold ">
                CONTACT
              </p>
              <p className="text-[#FFFFFF] text-sm w-[70%] md:text-base text-[0.7rem]">
                Cambri Private Limited{" "}
              </p>
              <p className="text-[#FFFFFF] text-sm w-[70%] md:text-base text-[0.7rem]">
                Suite #302, 4th Floor,
              </p>
              <p className="text-[#FFFFFF] text-sm w-[70%] md:text-base text-[0.7rem]">
                Green Domain Layout,
              </p>
              <p className="text-[#FFFFFF] text-sm w-[90%] md:text-base text-[0.7rem]">
                7th Cross Kundalahalli Main Road,
              </p>
              <p className="text-[#FFFFFF] text-sm w-[70%] md:text-base text-[0.7rem]">
                Bangalore - 560 048, Karnataka. India.{" "}
              </p>
              <p className="flex text-[#FFFFFF] text-sm  md:text-base items-center">
                <img src={email} alt="email" className="pr-2" />{" "}
                support@cambri.ai
              </p>
              <p className="flex  items-center">
                <img src={mobile} alt="mobile" className="pr-2" />
                <p className="text-[#FFFFFF] text-sm ">
                  +91 75699 83722, +1 (571) 699-6196
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex-row flex flex-col gap-2  justify-between  md:text-base text-center text-[0.6rem] text-[#8F98A9] mt-4 ">
          <div className="">
            <span
              className="cursor-pointer"
              onClick={() =>
                navigateUrl("https://app.cambri.ai/termsandconditions")
              }
            >
              Terms and Conditions{" "}
            </span>
            |{" "}
            <span
              className="cursor-pointer"
              onClick={() => navigateUrl("https://app.cambri.ai/PrivacyPolicy")}
            >
              Privacy Policy
            </span>
          </div>
          <div className=" ">2024. Copyright Reserved</div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
