import React from "react";
import laptop from "../../assets/laptop.png";

const ExamInterface = () => {
  return (
    <div className="text-center mb-32 md:mb-56 md:px-14 px-4 ">
      <p className="text-gray-700 text-xl sm:text-[40px] font-medium mb-12 md:mb-20">
        Exam Interface. As real as it can get.
      </p>
      <img src={laptop} alt="laptop" className="mb-16 md:mb-24 mx-auto" />
      <p className="text-gray-700 md:text-2xl font-normal leading-7">
        Our test interface undisputedly mirrors{" "}
        <span className="text-gray-700 md:text-2xl font-extrabold ">
          a real exam.
          </span>
        From the layout to the timing mechanisms, every detail is meticulously
        crafted to replicate the experience, providing candidates with a true
        simulation that prepares them effectively for the actual assessment.
      </p>
    </div>
  );
};

export default ExamInterface;
