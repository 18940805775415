import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { ReactNode, Suspense, lazy } from "react";
import Layout from './Components/Layout';
import Home from './pages/Home';
import BookaDemo from './pages/BookaDemo';
import RegistrationForm from './pages/RegistrationForm';

function App() {
  return (
    <Router>
    <Suspense fallback={<h1>Loading...............</h1>}>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
         
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="bookademo" element={<BookaDemo />} />
          <Route path="registration" element={<RegistrationForm />} />
        </Route>      
{/*         
        <Route path='*' element={<ErrorPage />}/> */}
      </Routes>
    </Suspense>
  </Router>
  );
}

export default App;
