import React from 'react'
import sopSteps from '../../assets/sopImg1.png'
import finalOutputScreen from '../../assets/sopImg2.png'
import sopStep5 from '../../assets/sopImg3.png'

const SOP = () => {
  return (
    <div className='text-gray-600 text-center lg:text-left mb-20 md:px-14 px-4 '>
      <div className='mb-10 md:mb-20'>
        <p className='text-2xl md:text-[32px] mb-3'>QUICK GLANCE AT</p>
        <p className='text-4xl md:text-5xl text-neutral-950 font-extrabold'>Statement of Purpose!</p>
      </div>
      <div className='lg:flex md:mb-24 mb-16'>
        <img src={sopSteps} alt="sop steps image" className='lg:w-[50%]'/>
        <img src={finalOutputScreen} alt="generated sop image" className='lg:w-[50%]'/>
      </div>
      <div className='lg:flex lg:justify-end'> 
      <div className='lg:w-[76%] lg:flex  lg:justify-between'>
        <img src={sopStep5} alt="sop step5" className='lg:w-[45%]'/>
        <div className='mt-5 lg:mt-20 lg:ml-24'>
          <p className='text-neutral-950 lg:text-[28px] text-xl font-medium mb-5 leading-7'>
          As Accurate As Human Minds.
          </p>
          <p className='text-gray-700 text-[1rem]lg:text-xl font-normal leading-7'>
          Cambri’s AI Engine is pioneer in
          real time context evaluation As Accurate As Human Minds.
          </p>
        </div>
      </div>
      </div>
    </div>
  )
}

export default SOP