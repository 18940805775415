import React from "react";
import performanceAnalysis from "../../assets/performanceAnalysis.png";
import preparationleft from "../../assets/preparationleft.png";
import preparationright from "../../assets/preparationright.png";

const PerfectMatch = () => {
  return (
    <div className="flex flex-col items-center lg:gap-20 md:gap-[2rem] gap-[1.5rem] mb-14 md:px-14 px-4 ">
      <h1 className="text-[#0C0C0C] font-extrabold lg:text-5xl  md:text-[2rem] self-start">
      Perfect Assessor. Match for you.
      </h1>

      <div className="relative">
        <img
          className="rounded-3xl"
          src={performanceAnalysis}
          alt="performanceAnalysis"
        />
        <div className="absolute lg-top-[10%] md:top-[7%] top-[5%] left-[5%] text-center md:space-y-1 lg:space-y-1">
          <h1 className="text-[#FFF] md:text-2xl lg:text-2xl text-xs  font-extrabold z-10">
            Advance Performance Analysis.
          </h1>
          <h1 className="text-[#FFF]  md:text-2xl lg:text-2xl text-xs font-extrabold z-10 ">
          As Accurate As Human Minds.
          </h1>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-center lg:gap-6 md:gap-6 -gap-[2.5rem]">
        <img
          className="lg:mb-12 md:mb-10 mb-10 lg-max-w-[100%] md-max-w-[100%] max-w-[10%] sm:flex self-start"
          src={preparationleft}
          alt="preparationleft"
        />
        <div className="flex flex-col gap-2 md:-gap-[1.5rem] text-center">
          <h1 className="text-[#0C0C0C] md:text-[1.75rem]  md:mt-[1.3rem] lg:text-[2rem] text-[1.25rem] font-extrabold">
            90% students see improvement in
          </h1>
          <h1 className="text-[#0C0C0C]  md:text-[1.75rem]  lg:text-[2rem]  text-[1.25rem] font-extrabold">
            first week of their preparation
          </h1>
        </div>
        <img
          className="mt-10 lg-max-w-[100%] md-max-w-[100%] max-w-[10%] sm:flex self-end"
          src={preparationright}
          alt="preparationleft"
        />
      </div>
    </div>
  );
};

export default PerfectMatch;
